.group{
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.group .list{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 7.5px 0;
}

.group .list .image{
    position: relative;
    width: 100%;
    height: 64px;
    display: block;
    overflow: hidden;
    background: #eee;
    border-radius: 20px;
}

.group .meta .title{
    height: 30px;
    margin: 10px 0;
}

.group .meta .intro{
    display: flex;
    align-items: center;
}

.group .meta .intro span{
    height: 20px;
    margin-right: 15px;
}

.loader-element{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: #dadada;
    margin-bottom: 2px;
    overflow: hidden;
}

.loader-element::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#dadada, #F2F2F2, #dadada);
    transform: rotate(120deg);
    animation: animation 1.5s infinite;
}

@keyframes animation{
    0%{left: -100%;}
    100%{left: 100%;}
}

