@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Nunito:wght@200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Ensure the main app content is always displayed */

/* App.css or your main CSS file */

/* Hide content in portrait mode */

/* Show warning message in portrait mode */
@media screen and (orientation: portrait) {
  .landscape-content {
    display: none;
  }
  .portrait-warning {
    display: block;
    text-align: center;
    margin-top: 50vh;
    transform: translateY(-50%);
    font-size: 1.5em;
  }
}

/* Dropdown */

/* DropdownList.css */
.custom-dropdown .dropdown-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(18, 20, 30, 1);
  background-color: rgba(248, 248, 248, 1);
}

.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item:focus,
.custom-dropdown .dropdown-item:active {
  background-color: rgba(169, 169, 169, 1); /* Dark gray color */
  color: #f8f8f8; /* White text */
  border-radius: "8px";
}

/* ************************************************************************************************************************ */
/* Scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 8px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

/* ************************************************************************************************************************ */

/* Home Grid gap */
.custom-gap {
  gap: 12px;
}
/* ************************************************************************************************************************ */

.custom-box {
  border-radius: 18px;
  background-color: white;
  box-shadow: 0px 0px 10px 2px #d2d2d240;
  min-height: 332px;
  height: 96%;
}

/* ************************************************************************************************************************ */

/* Switch  */
.custom-switch .ant-switch-handle::before {
  background-color: rgba(224, 111, 44, 1) !important;
}

/* ************************************************************************************************************************ */

/* delelte account button */
#deleteAccountButton {
  width: 100%;
  height: 75%;
  background-color: #f8f8f8;
  border-radius: 18px;
  border: 1px solid #e06f2c;
  color: #e06f2c;
  transition: background-color 0.3s, color 0.3s; /* Add transition for smooth effect */
}

/* ************************************************************************************************************************ */

/* Define styles for hover state */
#deleteAccountButton:hover {
  background-color: #e06f2c;
  color: white !important;
}

/* ************************************************************************************************************************ */

/* Define styles for active (clicked) state */
#deleteAccountButton:active {
  background-color: #c05723;
  color: white;
}

/* ************************************************************************************************************************ */

/* Override styles for the Segmented antd component  */
.custom-segmented-tab .ant-segmented-item-selected {
  background-color: #e06f2c;
  border-radius: 5px;
  color: white;
}

.custom-segmented-tab .ant-segmented-thumb {
  background-color: #e06f2c;
  border-radius: 5px;
}

/* ************************************************************************************************************************ */

/* Selectable grid CSS */
.grid {
  display: grid;
  gap: 5px;
  user-select: none;
}

/* ==========Floating input css=========== */
.floating-input-group {
  position: relative;
}

.floating-input {
  padding: 10px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  outline: none;
  /* border: 1px solid #04AA6D; */
  background-color: #f8f8f8;
  border-radius: 9px;
}

.floating-placeholder {
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 14px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.28);
  transition: 0.3s;
  pointer-events: none;
}

.floating-input:focus + .floating-placeholder {
  top: -10px;
  color: rgba(0, 0, 0, 0.28);
  background-color: transparent;
}

.floating-fill {
  top: -10px;
  color: rgba(0, 0, 0, 0.28);
  background-color: transparent;
}

.mask {
  -webkit-mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  mask: linear-gradient(to right, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
}

/* ************************************************************************************************************************ */

/* Custom styles for the antd Button component */
.custom-button {
  background-color: transparent;
  color: #e06f2c;
  border: 1px solid #e06f2c;
  padding: 0 5px;
  cursor: pointer;
  height: 30px;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.custom-button:hover:not(:disabled) {
  background-color: #e06f2c;
  color: white;
}

.custom-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* =========Checkbox CSS========== */
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 2.9rem;
  height: 1.3rem;
  clip-path: polygon(
    20% 55%,
    35% 70%,
    50% 85%,
    85% 35%,
    75% 25%,
    50% 65%,
    30% 45%
  );
  transform: scale(0);
  background-color: black;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:hover {
  color: black;
}

/*  remove the default blue background color of an input field that is auto-filled by the browser  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* ************************************************************************************************************************ */
